.input-container {
    background-color: #3f414e;
    padding: 4px 8px;
    border-radius: 6px;
    box-shadow: 0 0 10px #292b34;
    width: 600px;
    position: absolute;
    bottom: 20px;
    z-index: 2;
}

@media screen and (max-width: 825px) {
    .input-container {
        width: calc(100% - 20px);
    }
}

.fade {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 136px;
    z-index: 1;
    background-image: linear-gradient(180deg,rgba(53,55,64,0),#353740 58.85%);
}

.input {
    background-color: inherit;
    outline: none;
    border: none;
    color: white;
    resize: none;
    max-height: 200px;
    height: 24px;
    @apply w-full
}

.input:focus {
    outline: none;
    border: none;
}